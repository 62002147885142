import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import "./LuckyDraw.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFolderOpen,
  faGear,
  faPlay,
  faRepeat,
  faRotate,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  FloatingLabel,
  Form,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const LuckyDraw = () => {
  const [col1Numbers, setCol1Numbers] = useState([]);
  const [col2Numbers, setCol2Numbers] = useState([]);
  const [currentNumber, setCurrentNumber] = useState("");
  const [spinCount, setSpinCount] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [winners, setWinners] = useState([]);
  const [showFireworks, setShowFireworks] = useState(false);
  const [zoomEffect, setZoomEffect] = useState(false); // Thêm state để điều khiển hiệu ứng zoom
  const [showLucky, setShowLucky] = useState(true); //
  const [background, setBackground] = useState("");
  const [showWinner, setShowWinner] = useState(false);
  const [titleLucky, setTitleLucky] = useState("Quay số cùng Katec");
  const [sloganLucky, setSloganLucky] = useState("Đón tết ring quà");
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
  });

  const [styleColorLucky, setStyleColorLucky] = useState("#FFD700");
  const [delaySpin, setDelaySpin] = useState(150);

  useEffect(() => {
    document.title = "Quay số may mắn";
    handleFileReadFromPublic();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setBackground(imageURL);

      setBackgroundStyle({
        ...backgroundStyle,
        backgroundImage: `url(${imageURL})`,
        backgroundPosition: "center",
        position: "fixed",
        height: "100%",
        width: "100%",
      });
    }
  };

  const [containerStyle, setContainerStyle] = useState({
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    fontFamily: "'Pacifico', cursive",
  });

  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleDragStart = (e) => {
    setDragging(true);
    const rect = e.target.getBoundingClientRect();
    setOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleDrag = (e) => {
    if (dragging) {
      setContainerStyle((prevStyle) => ({
        ...prevStyle,
        top: `${e.clientY - offset.y}px`,
        left: `${e.clientX - offset.x}px`,
        transform: "unset",
      }));
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const [currentNumbers, setCurrentNumbers] = useState(["0", "0", "0", "0"]);

  const handleFileReadFromPublic = async () => {
    const response = await fetch("/luckyDraw/number_lucky_draw.xlsx");
    const arrayBuffer = await response.arrayBuffer();
    const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });

    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

    const col1 = rows.map((row) => row[0]).filter((val) => val !== undefined);
    const col2 = rows.map((row) => row[1]);

    setCol1Numbers(col1);
    setCol2Numbers(col2);
  };

  // Hàm tải file và xử lý dữ liệu từ Excel
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      const col1 = rows.map((row) => row[0]).filter((val) => val !== undefined);
      const col2 = rows.map((row) => row[1]);

      setSpinCount(0);
      setWinners([]);
      setCol1Numbers(col1);
      setCol2Numbers(col2);
    };

    reader.readAsArrayBuffer(file);
  };

  // Hàm bắt đầu quay số
  const startJackpot = () => {
    if (isSpinning) return; // Ngăn việc quay nhiều lần cùng lúc

    // Kiểm tra nếu không còn số hợp lệ để quay
    if (
      spinCount >= col2Numbers.length &&
      col1Numbers.length === winners.length
    ) {
      setCurrentNumber("Không còn số hợp lệ để quay!");
      return;
    }

    setIsSpinning(true);

    let winner;
    const col2Value = col2Numbers[spinCount];

    // Kiểm tra giá trị trong cột 2
    if (
      col2Value !== null &&
      col2Value !== undefined &&
      !winners.includes(col2Value)
    ) {
      winner = col2Value; // Sử dụng giá trị từ cột 2
    } else {
      // Lấy giá trị ngẫu nhiên từ cột 1 nếu không tìm thấy giá trị hợp lệ trong cột 2
      const remainingNumbers = col1Numbers.filter(
        (num) => !winners.includes(num)
      );
      if (remainingNumbers.length > 0) {
        const randomIndex = Math.floor(Math.random() * remainingNumbers.length);
        winner = remainingNumbers[randomIndex];
      }
    }

    if (winner !== undefined) {
      setSpinCount((prev) => prev + 1);

      // Hiệu ứng quay từng cột
      winner
        .toString()
        .split("")
        .forEach((num, index) => {
          let count = 0;
          const interval = setInterval(() => {
            setCurrentNumbers((prev) => {
              const updatedNumbers = [...prev];
              updatedNumbers[index] = (count % 10).toString(); // Hiển thị số từ 0 -> 9
              return updatedNumbers;
            });

            count++;
            if (count > 30 + index * 5) {
              // Số vòng quay, thêm độ trễ cho từng cột
              clearInterval(interval);

              setCurrentNumbers((prev) => {
                const updatedNumbers = [...prev];
                updatedNumbers[index] = num; // Dừng lại ở số mục tiêu
                return updatedNumbers;
              });

              // Khi cột cuối cùng dừng, kết thúc hiệu ứng quay
              if (index === winner.toString().length - 1) {
                setIsSpinning(false);

                setTimeout(() => {
                  setShowFireworks(true); // Kích hoạt hiệu ứng pháo bông
                  setZoomEffect(true); // Kích hoạt hiệu ứng zoom cho số trúng
                }, 500);

                setTimeout(() => {
                  setWinners((prev) => [...prev, winner]);
                  setShowFireworks(false); // Tắt hiệu ứng pháo bông sau 3 giây
                  setZoomEffect(false); // Tắt hiệu ứng zoom sau khi hiệu ứng kết thúc
                }, 3500);
              }
            }
          }, delaySpin); // Tốc độ quay (ms)
        });
    } else {
      setCurrentNumber("Không còn số hợp lệ để quay!");
      setIsSpinning(false);
    }
  };

  const [showSideBar, setShowSideBar] = useState(false);

  const handleClose = () => setShowSideBar(false);
  const handleShow = () => setShowSideBar(true);

  const fontHtml = [
    "'Arial', sans-serif",
    "'Tahoma', sans-serif",
    "'Verdana', sans-serif",
    "'Times New Roman', serif",
    "'Georgia', serif",
    "'Trebuchet MS', sans-serif",
    "'Roboto', sans-serif",
    "'Open Sans', sans-serif",
    "'Lora', serif",
    "'Merriweather', serif",
    "'Noto Sans', sans-serif",
    "'Noto Serif', serif",
    "'Quicksand', sans-serif",
    "'Playfair Display', serif",
    "'Montserrat', sans-serif",
    "'Poppins', sans-serif",
    "'Pacifico', cursive",
    "'Dancing Script', cursive",
    "'Raleway', sans-serif",
    "'Comfortaa', sans-serif",
    "'Slabo 27px', serif",
    "'Be Vietnam Pro', sans-serif",
    "Helvetica",
    "Impact",
    "Cursive",
    "Fantasy",
    "Monospace",
    "System",
    "Symbol",
    "Script",
    "Courier New",
    "Lucida Console",
    "MS Sans Serif",
    "Palatino Linotype",
    "Bookman Old Style",
    "Garamond",
    "Hoefler Text",
    "Andale Mono",
  ];

  const pastelColors = [
    "#FFD700",
    "#C23B22",
    "#FF6F61", // Coral
    "#FF8C42", // Deep Peach
    "#FFC75F", // Sunflower Yellow
    "#4CAF50", // Vibrant Green
    "#42A5F5", // Sky Blue
    "#AB47BC", // Purple Orchid
    "#FF4081", // Hot Pink
    "#7CB342", // Forest Green
    "#FFB300", // Vivid Orange
    "#5C6BC0", // Indigo
    "#E91E63", // Rose
    "#FFA726", // Amber
    "#9C27B0", // Deep Lavender
    "#FF7043", // Burnt Orange
    "#795548", // Mocha
  ];

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(1);

  const togglePlay = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => {
        console.error("Không thể phát âm thanh:", error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume; // Cập nhật âm lượng cho audio element
    }
  };

  return (
    <div
      className="background-lucky"
      style={background ? backgroundStyle : { height: "100vh" }}
    >
      <div
        className="container-lucky"
        style={background ? containerStyle : { ...containerStyle }}
        onMouseMove={handleDrag}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
      >
        <div
          className="lucky-draw"
          style={{ display: showLucky ? "block" : "none" }}
        >
          <h1
            onMouseDown={handleDragStart}
            style={{
              cursor: "pointer",
              color: styleColorLucky,
            }}
          >
            {titleLucky}
          </h1>
          <h2 style={{ color: styleColorLucky }}>{sloganLucky}</h2>

          <button
            className="btn-close-tab"
            onClick={() => {
              setShowLucky(false);
            }}
            title="Đóng"
          >
            x
          </button>

          <div className="jackpot-container">
            <div
              className={`jackpot-display ${zoomEffect ? "zoom-number" : ""}`}
            >
              {currentNumbers.map((num, index) => (
                <div
                  key={index}
                  className={`digit ${zoomEffect ? "change-color" : ""}`}
                >
                  {num}
                </div>
              ))}
            </div>
          </div>

          <button
            className="button-spinner"
            onClick={startJackpot}
            disabled={
              isSpinning ||
              (col1Numbers.length === 0 && col2Numbers.length === 0)
            }
          >
            {isSpinning ? (
              <>
                <FontAwesomeIcon icon={faRotate} spin /> Đang quay...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faRotate} /> Quay số
              </>
            )}
          </button>
          <a
            href="#"
            className="btn-show-tab-winner"
            style={{ color: styleColorLucky, opacity: 0.6 }}
            onClick={() => {
              setShowWinner(!showWinner);
            }}
          >
            Danh sách số đã trúng!
          </a>
          <div
            className="winners hidden-scroll"
            style={{ display: showWinner ? "block" : "none" }}
          >
            <h3
              style={{
                color: styleColorLucky,
              }}
            >
              Danh sách số đã trúng
              <button
                className="btn-close-tab"
                onClick={() => {
                  setShowWinner(false);
                }}
                title="Đóng"
              >
                x
              </button>
            </h3>
            {winners.length > 0 ? (
              <>
                <ul>
                  {winners.map((winner, index) => (
                    <li
                      key={index}
                      className="fw-bold"
                      style={{
                        color: styleColorLucky,
                      }}
                    >
                      {winner}
                    </li>
                  ))}
                </ul>
                <Button
                  size="sm"
                  variant="outline-warning"
                  onClick={() => {
                    setWinners([]);
                    setIsSpinning(false);
                  }}
                  disabled={isSpinning}
                >
                  <FontAwesomeIcon icon={faRepeat} /> Làm mới
                </Button>
              </>
            ) : (
              <p style={{ color: styleColorLucky, opacity: 0.5 }}>
                Chưa có số nào trúng thưởng!
              </p>
            )}
          </div>
        </div>

        {showFireworks && <div className="fireworks"></div>}
      </div>

      <>
        <Button
          size="sm"
          variant="outline-secondary "
          onClick={handleShow}
          style={{ position: "fixed", top: 0, right: 0, zIndex: "2" }}
        >
          <FontAwesomeIcon icon={faGear} />
        </Button>

        <Offcanvas placement="end" show={showSideBar} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <FontAwesomeIcon icon={faGear} /> Cài đặt
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FloatingLabel
              controlId="floatingTextarea"
              label="Tiêu đề*"
              className="mb-3"
            >
              <Form.Control
                value={titleLucky}
                onChange={(e) => setTitleLucky(e.target.value)}
              />
            </FloatingLabel>

            <FloatingLabel className="mb-3" label="Slogan">
              <Form.Control
                as="textarea"
                value={sloganLucky}
                onChange={(e) => setSloganLucky(e.target.value)}
                style={{ height: "100px" }}
              />
            </FloatingLabel>

            <FloatingLabel label="Thời gian chờ (ms)" className="mb-3">
              <Form.Control
                type="number"
                min="1"
                value={delaySpin}
                onChange={(e) => setDelaySpin(e.target.value)}
              />
            </FloatingLabel>

            <div className="d-flex">
              <Form.Label className="me-2">Font chữ: </Form.Label>

              <NavDropdown
                title={containerStyle.fontFamily}
                style={{ fontFamily: containerStyle.fontFamily }}
              >
                {fontHtml.map((font) => {
                  return (
                    <NavDropdown.Item
                      key={font}
                      style={{ fontFamily: font }}
                      onClick={() =>
                        setContainerStyle({
                          ...containerStyle,
                          fontFamily: font,
                        })
                      }
                    >
                      {font}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </div>

            <div className="d-flex">
              <Form.Label className="me-2">Màu sắc chữ: </Form.Label>

              <NavDropdown
                title={styleColorLucky}
                style={{ color: styleColorLucky, fontWeight: "bold" }}
              >
                {pastelColors.map((color) => {
                  return (
                    <NavDropdown.Item
                      key={color}
                      style={{ color: color, fontWeight: "bold" }}
                      onClick={() => setStyleColorLucky(color)}
                    >
                      {color}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </div>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Thay đổi hình nền:</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                title="Thay đổi hình nền"
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                Thay đổi danh sách số may mắn:{" "}
                <a
                  className="text-italic"
                  href={`${process.env.PUBLIC_URL}/luckyDraw/number_lucky_draw.xlsx`}
                  download="number_lucky_draw.xlsx"
                >
                  File mẫu <FontAwesomeIcon icon={faDownload} />
                </a>
              </Form.Label>
              <Form.Control
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                title="Thay đổi file excel số may mắn"
                disabled={isSpinning}
              />
            </Form.Group>

            <Form.Label>Nhạc nền:</Form.Label>
            <div className="d-flex mb-3">
              <Button className="me-2" onClick={togglePlay}>
                <FontAwesomeIcon icon={faPlay} /> {isPlaying ? "Dừng" : "Phát"}
              </Button>

              <div className="volume-container">
                <label className="volume-label" htmlFor="volume">
                  Âm lượng:
                </label>
                <input
                  id="volume"
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="volume-slider"
                />
              </div>
            </div>

            <Button
              variant={`${showLucky ? "danger" : "success"} me-2`}
              onClick={() => setShowLucky(!showLucky)}
              title="Mở quay số trúng thưởng"
            >
              {showLucky ? (
                <>
                  <FontAwesomeIcon icon={faTimes} /> Đóng quay số
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faFolderOpen} /> Mở quay số
                </>
              )}
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      </>
      <i
        className="text-muted"
        style={{ position: "fixed", bottom: 0, left: "10px" }}
      >
        Design by Katec
      </i>

      <audio ref={audioRef} loop>
        <source
          src={`${process.env.PUBLIC_URL}/luckyDraw/nhac_xo_so_mien_bac.mp3`}
          type="audio/mpeg"
        />
        Trình duyệt của bạn không hỗ trợ phát âm thanh.
      </audio>
    </div>
  );
};

export default LuckyDraw;
